import { createSelector } from 'reselect'
import entryDataSelector from './entryDataSelector'
import { FeatureScope } from '../../entities/EntryData'

const modeSelector = createSelector(
    entryDataSelector,
    (entryData): FeatureScope => (
        entryData.featureScopes.find((featureScope) => (
            featureScope === 'fkt'
            || featureScope === 'pdw'
            || featureScope === 'leasing'
            || featureScope === 'kauf'
        ))
    ),
)

export default modeSelector
