import { connect, MapStateToProps } from 'react-redux'
import summarySelector from '../../redux/selectors/summarySelector'
import { fetchSummaryAsync } from '../../redux/actions/app/summary.actions'
import { Props } from './OverviewView'
import orderKeySelector from '../../redux/selectors/orderKeySelector'
import configuredCarlineTitleSelector from '../../redux/selectors/carlines/configuredCarlineTitleSelector'
import overviewViewIsLoadingSelector from '../../redux/selectors/is-loading/overviewViewIsLoadingSelector'
import generalInformationSelector from '../../redux/selectors/generalInformationSelector'
import equipmentInformationSelector from '../../redux/selectors/equipments/equipmentInformationSelector'

type StateProps = Pick<Props, (
    'summary' |
    'carlineTitle' |
    'orderKey' |
    'isLoading' |
    'generalInformation' |
    'equipmentInformation'
)>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    summary: summarySelector(state),
    carlineTitle: configuredCarlineTitleSelector(state),
    orderKey: orderKeySelector(state),
    isLoading: overviewViewIsLoadingSelector(state),
    generalInformation: generalInformationSelector(state),
    equipmentInformation: equipmentInformationSelector(state),
})

type DispatchProps = Pick<Props, 'loadSummaryData'>

const mapDispatchToProps: DispatchProps = {
    loadSummaryData: fetchSummaryAsync.request,
}

export default connect(mapStateToProps, mapDispatchToProps)
