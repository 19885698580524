import React, {FunctionComponent} from 'react'
import {ConflictItem} from '../../entities/ConfigurationResponse'
import {Checkbox} from '../form'
import MediaImg from '../media-img'
import useTranslation from '../../hooks/useTranslation'
import style from './foresightedBuildabilityConflictStepItem.css'
import InfoButton from '../info-button'
import InfoI from '../info-i'
import Equipment from '../../entities/Equipment'

export type Props = {
    item: ConflictItem
    checkboxIsChecked: boolean
    checkboxIsVisible: boolean
    checkboxIsDisabled: boolean
    openInfoModal: Function
    selectEquipment: Function
    deselectEquipment: Function
    fullEquipment?: Equipment
}

const getDefaultEvaKey = (equipmentId: string): string => {
    return equipmentId.substring(equipmentId.length - 3)
}

const ForesightedBuildabilityConflictStepItem: FunctionComponent<Props> = (props) => {
    const {
        item,
        checkboxIsChecked,
        checkboxIsVisible,
        checkboxIsDisabled,
        openInfoModal,
        deselectEquipment,
        selectEquipment,
        fullEquipment,
    } = props
    const {t} = useTranslation()

    const {
        id,
        name,
        price,
        content,
    } = item.equipment
    const evaKey = fullEquipment?.evaKey ?? getDefaultEvaKey(id)
    const media = fullEquipment?.media ?? []

    const openModal = (activeMedia: string | null = null) => () => openInfoModal({
        title: name,
        media,
        activeMedia,
        content,
    })

    const changeHandler = (): void => {
        if (checkboxIsChecked) {
            deselectEquipment(id)
        } else {
            selectEquipment(id)
        }
    }

    const checkboxElement = checkboxIsVisible ? (
        <span>
            <Checkbox
                data-cy={`vbbk-checkbox-${id}`}
                checked={checkboxIsChecked}
                disabled={checkboxIsDisabled}
                onChange={changeHandler}
            />
        </span>
    ) : null
    const label = t(item.conflictType === 'ADD'
        ? 'foresightedBuildabilityModal.plus'
        : 'foresightedBuildabilityModal.minus')
    const className = [
        style.container,
        checkboxIsVisible ? style.containerWithCheckbox : '',
    ].join(' ')
    const hasInfo = media && media.length > 0
    const imgElement = hasInfo && <MediaImg alt={name} media={media} width={100}/>
    const infoLabel = <InfoI isForced={false}/>
    const infoElement = hasInfo ? (
        <InfoButton
            openModal={openModal(imgElement !== null ? 'image' : null)}
            dataCy={`info-button-${id.replace(/\\/g, '.')}`}
        >
            {infoLabel}
        </InfoButton>
    ) : null

    return (
        <div className={className}>
            {checkboxElement}
            <span>
                <span className={style.label}>{label}</span>
                {' '}
                {name}
                {' '}
                <span className={style.evaKey}>{evaKey}</span>
            </span>
            <span className={style.priceAndInfoContainer}>
            <span className={style.price}>{price}</span>
            <span className={style.infoElement}>
                {infoElement}
            </span>
            </span>
        </div>
    )
}

export default ForesightedBuildabilityConflictStepItem
