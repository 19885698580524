import React from 'react'
import CartboxButton from '../cartbox-button'
import LeasingIcon from '../../assets/media/financial-services-small.svg'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    showLeasingModal: () => void
    isVisible: boolean
}

const LeasingButton: React.FC<Props> = ({ showLeasingModal, isVisible }) => {
    const { t } = useTranslation()

    if (!isVisible) {
        return null
    }

    return (
        <CartboxButton
            onClick={showLeasingModal}
            icon={<LeasingIcon />}
            label={t('leasing.buttonLabel')}
        />
    )
}

export default LeasingButton
