import React, { FunctionComponent } from 'react'
import Equipment from '../../entities/Equipment'
import EquipmentTableRow from './EquipmentTableRow'
import { Table } from '../table'
import EquipmentInformation from "../../entities/EquipmentInformation"
import { RecommendationFamilyStatus } from '../../entities/RecommendationFamilyStatus'

export type EquipmentViewVariant = 'standard' | 'conflict' | 'model_conflict'

export type Props = {
    equipments: Equipment[]
    familyStatus: RecommendationFamilyStatus
    viewVariant: EquipmentViewVariant
    selectEquipment: Function
    deselectEquipment: Function
    openInfoModal: Function
    priceColWidth?: string
    equipmentInformation: EquipmentInformation
}

const EquipmentTable: FunctionComponent<Props> = (props) => {
    const {
        equipments,
        viewVariant,
        selectEquipment,
        deselectEquipment,
        openInfoModal,
        priceColWidth,
        equipmentInformation,
        familyStatus,
    } = props

    if (equipments.length === 0) {
        return null
    }

    const tableRows = equipments.map((equipment) => (
        <EquipmentTableRow
            key={`${equipment.id}-${viewVariant}`}
            equipment={equipment}
            familyStatus={familyStatus}
            viewVariant={viewVariant}
            selectEquipment={selectEquipment}
            deselectEquipment={deselectEquipment}
            openInfoModal={openInfoModal}
            priceColWidth={priceColWidth}
            equipmentInformation={equipmentInformation}
        />
    ))

    return (
        <Table>
            <tbody>
                {tableRows}
            </tbody>
        </Table>
    )
}

export default EquipmentTable
