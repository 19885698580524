import React, {FunctionComponent} from 'react'
import {Markup} from 'interweave'
import SummaryData from '../../entities/SummaryData'
import useOnMount from '../../hooks/useOnMount'
import {Table, Td, Tr} from '../table'
import {getRemarkLabels} from '../disclaimer-text'
import style from './summaryTable.css'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    summary: SummaryData
    loadSummaryData: Function
}

const SummaryTable: FunctionComponent<Props> = (props) => {
    const { summary, loadSummaryData } = props
    const { t, i18n } = useTranslation()

    useOnMount(() => {
        loadSummaryData()
    })

    if (summary.informations.length === 0) {
        return null
    }

    const remarkLabels = getRemarkLabels(summary.remarks)

    const rowElements = summary.informations.map((summaryInfo) => {
        const {
            label,
            image,
            value,
            labelRemarkRefId,
            valueRemarkRefId,
        } = summaryInfo

        const imageElement = image ? <img src={image} alt='car-label'/> : null

        const labelTranslationKey = `summaryData.${label}`
        const possiblyTranslatedLabel = i18n.exists(labelTranslationKey)
            ? t(labelTranslationKey)
            : label

        const imageLine = <Td colspan={2} className={style.col}>
            {imageElement}
        </Td>
        const tableLine = <><Td className={style.col}>
                    <span>
                        <Markup tagName="span" content={possiblyTranslatedLabel}/>
                        {' '}
                        {remarkLabels[labelRemarkRefId]}
                    </span>
        </Td>
            <Td className={`${style.col} ${style.valueCol}`}>
                <Markup tagName="span" content={value}/>
                {' '}
                {remarkLabels[valueRemarkRefId]}
            </Td></>

        const tableRow = image != null  ? imageLine : tableLine
        return (
            <Tr key={label}>{tableRow}</Tr>
        )
    })

    return (
        <Table>
            <tbody>
                {rowElements}
            </tbody>
        </Table>
    )
}

export default SummaryTable
