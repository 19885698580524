import { connect, MapStateToProps } from 'react-redux'
import { Props } from './LeasingButton'
import { showLeasingModal } from '../../redux/actions/app/leasing.actions'
import modeIsLeasingSelector from '../../redux/selectors/feature-scope/modeIsLeasingSelector'

type StateProps = Pick<Props, 'isVisible'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    isVisible: modeIsLeasingSelector(state),
})

type DispatchProps = Pick<Props, 'showLeasingModal'>

const mapDispatchToProps: DispatchProps = {
    showLeasingModal,
}

export default connect(mapStateToProps, mapDispatchToProps)
