import { CarlineId } from '../entities/Carline'
import VehicleCode from '../entities/VehicleCode'
import { PriceViewFlag } from '../entities/PriceViewFlag'
import config from '../config'

const { API_URL } = config

export const API_URL_ENTRY = `${API_URL}/api/v1/entry`
export const API_URL_CARLINES = `${API_URL}/api/v1/catalog/carlines`
export const API_URL_MODELS = `${API_URL}/api/v1/catalog/models`
export const API_URL_CONFIGURATION = `${API_URL}/api/v1/configuration`
export const API_URL_TECHNICAL_DATA = `${API_URL}/api/v1/technicalData`
export const API_URL_EFFICIENCY_DATA = `${API_URL}/api/v1/efficiencyData`
export const API_URL_CART = `${API_URL}/api/v1/cart`
export const API_URL_EQUIPMENT = `${API_URL}/api/v1/catalog/equipment`
export const API_URL_VISUALIZATION = `${API_URL}/api/v1/visualization`
export const API_URL_VEHICLECODE = `${API_URL}/api/v1/vehiclecode`
export const API_URL_FINANCING = `${API_URL}/api/v1/financing`
export const API_URL_CARSTORE = `${API_URL}/api/v1/carstore`
export const API_URL_LAST_CONFIGURATIONS = `${API_URL}/api/v1/lastConfigurations`
export const API_URL_LEASING = `${API_URL}/api/v1/leasing`

export const getEntryApiUrl = (contextId: string, params: string): string => `${API_URL_ENTRY}/${contextId}?${params}`

export const getCarlinesApiUrl = (): string => `${API_URL_CARLINES}`

export const getModelsApiUrl = (carlineId: CarlineId): string => `${API_URL_MODELS}?carlineId=${carlineId}`

export const getConfigurationUrl = (): string => API_URL_CONFIGURATION

export const getAddConfigurationUrl = (triggerId: string): string => `${API_URL_CONFIGURATION}/add?triggerId=${triggerId}`

export const getConflictSolutionResolveUrl = (adopt: 'ACCEPT' | 'REJECT' | 'CANCEL'): string => `${API_URL_CONFIGURATION}/add?adopt=${adopt}`

export const getRemoveConfigurationUrl = (triggerId: string): string => `${API_URL_CONFIGURATION}/remove?triggerId=${triggerId}`

export const getConfigurationByVehiclecodeApiUrl = (vehiclecode: VehicleCode): string => `${API_URL_CONFIGURATION}/${vehiclecode}`

export const loadConfigurationByOrderKey = (orderKey: string): string => `${API_URL_CONFIGURATION}/fast/${orderKey}`

export const getPutFastConfigurationApiUrl = (): string => `${API_URL_CONFIGURATION}/fast`

export const getEquipmentApiUrl = (): string => `${API_URL_EQUIPMENT}`

export const getFetchCartApiUrl = (): string => `${API_URL_CART}`

export const getPutCartApiUrl = (priceViewFlag: PriceViewFlag): string => `${API_URL_CART}?priceViewFlag=${priceViewFlag}`

export const getTechnicalDataApiUrl = (configurationString: string): string => `${API_URL_TECHNICAL_DATA}?configurationString=${configurationString}`

export const getVisualizationApiUrl = (): string => `${API_URL_VISUALIZATION}`

export const getCreateVehiclecodeApiUrl = (): string => `${API_URL_VEHICLECODE}`

export const loadConfigurationByVehiclecodeApiUrl = (vehiclecode: VehicleCode): string => `${API_URL_VEHICLECODE}/${vehiclecode}`

export const getResetConfigurationApiUrl = (): string => `${API_URL_CONFIGURATION}/start`

export const getConfigurationSummaryApiUrl = (): string => `${API_URL_CONFIGURATION}/summary`

export const getEfficiencyDataApiUrl = (): string => `${API_URL_EFFICIENCY_DATA}/formatted`

export const getLeasingMatrixUrl = (carlineId: CarlineId): string => `${API_URL_LEASING}?carlineId=${carlineId}`

export const getChooseLeasingRateUrl = (rateId: string): string => `${API_URL_LEASING}/${rateId}`

export const getCheckoutApiUrl = (): string => `${API_URL_CONFIGURATION}/checkout`
